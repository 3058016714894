<template>
  <div class="ssss" id="ssss">
    <loading :active.sync="showLoading"
             :can-cancel="false"
             color="#F4B552"
             background-color="#707070"
             :is-full-page="fullPage"></loading>
    <div class="p-navbar">
      <div class="p-contain">
        <b-navbar style="height: 50px;display: flex;flex-direction: row;justify-content: space-between;"
                  toggleable="lg" type="dark" variant="">
          <b-navbar-brand href="#"><img width="112px" src="../assets/img/logo.png"></b-navbar-brand>

          <b-navbar class="menu-right" is-nav right>
            <!-- Right aligned nav items -->
            <div class=""></div>
            <div class="p-float-right">

              <b-navbar-nav class="ml-auto" right>
                <div v-b-modal.modallang class="lang-group">
                  <img src="../assets/img/lang_create.png">
                </div>

                <!--未登录--开始-->
                <b-navbar-nav v-if="!this.$store.state.is_login" class="flex-center-v">
                  <a class="purse-link" @click="this.userLogin">{{$t('m.login')}}</a>
                </b-navbar-nav>
                <!--未登录--结束-->

                <!--登录--开始-->
                <div v-if="this.$store.state.is_login" class="user-group">
                  <div v-b-modal.modaluser class="user-circle">
                    <img width="22px"  src="../assets/img/user.png">
                  </div>

                </div>


                <div v-if="this.$store.state.is_login" class="pursr-group">
                  <div v-b-modal.modalpurse class="group-circle">
                    <img width="22px" src="../assets/img/purse.png">
                  </div>

                </div>
                <!--登录--结束-->

              </b-navbar-nav>
            </div>
          </b-navbar>

        </b-navbar>
      </div>

      <!--banner三个弹框-开始-->
      <b-modal modal-class="modallang xs" id="modallang" hide-header hide-footer centered>
        <div class="d-block text-center">
          <div class="pmodal-head">
            <div class="pmodal-head-title">
              {{$t('language')}}
            </div>
            <div class="pmodal-head-close" @click="$bvModal.hide('modallang')">
              <img src="../assets/img/close-green.png" alt="">
            </div>
          </div>
          <div class="pmodal-body m-body-lang">
            <div @click="changeLang('en')">English</div>
            <div @click="changeLang('zh')">中文(简体)</div>
<!--            <div @click="changeLang('tw')">中文(繁体)</div>-->
          </div>
        </div>
      </b-modal>

      <b-modal modal-class="modalpurse xs"  v-if="this.$store.state.is_login"  id="modalpurse" hide-header hide-footer centered>
        <div class="d-block text-center">
          <div class="pmodal-head">
            <div class="pmodal-head-title">
              {{$t('wallet')}}
            </div>
            <div class="pmodal-head-close" @click="$bvModal.hide('modalpurse')">
              <img src="../assets/img/close-green.png" alt="">
            </div>
          </div>
          <div class="pmodal-body">
            <div class="zh-user" style="">
              <div class="zh-user-33" @click="log_out()">{{$t('logout')}}</div>
            </div>
          </div>
        </div>
      </b-modal>

      <b-modal modal-class="modaluser xs"  v-if="this.$store.state.is_login"  id="modaluser" hide-header hide-footer centered>
        <div class="d-block text-center">
          <div class="pmodal-head">
            <div class="pmodal-head-title">
              {{$t('user')}}
            </div>
            <div class="pmodal-head-close" @click="$bvModal.hide('modaluser')">
              <img src="../assets/img/close-green.png" alt="">
            </div>
          </div>
          <div class="pmodal-body" style="padding: 30px 0px;box-sizing: border-box;">
            <div class="zh-user" style="">
              <div class="zh-user-1">{{$t('user_amount')}}</div>
              <div class="zh-user-2">{{ account_balance }}</div>
            </div>
          </div>
        </div>
      </b-modal>
      <!--banner三个弹框-结束-->
    </div>
    <div class="banner-bg">
      <div class="banner-bg-con">
        <div class="sj">
          <div class="p-con-banner">
            <div class="p-count">
              <div class="p-count-text">{{$t('create_jc_ze')}}</div>
              <countTo :decimals='2' :duration='2000' :endVal='total_bonus' class="p-count-sz"></countTo>
              <div class="p-count-dw">TKM</div>
              <div class="p-count-total" >··· {{$t('tip_has')}}{{ total_donation_user }}{{$t('tip_has_2')}} ···</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="k-bg">
      <div class="k-con">
        <!--未登录-开始-->
        <div v-if="!this.$store.state.is_login" class="k-kj">
<!--          <div class="k-btn" @click="this.userLogin">{{$t('lj_3')}}</div>-->
          <div class="k-title">-// {{$t('create_title_create')}} //-</div>
          <div class="k-tip">{{$t('create_tip_connect')}}</div>
        </div>
        <!--未登录-结束-->
        <!--        有中奖记录-->
        <div v-else>
          <div class="k-title">··· ———————— ···</div>
          <div class="k-kj">
            <div class="create-total">{{$t('tip_your_wallet')}}</div>
            <div class="create-total">{{ userAccount }}</div>

            <div class="create-total" style="color: #F5C94F;">
              {{$t('tip_end')}}
            </div>
          </div>
          <div class="k-title">-// {{$t('create_title_create')}} //-</div>
          <div class="create-total" v-if="user_donation_num<=0">{{$t('you_has_donation')}}{{ user_donation_num }}{{$t('has_donations_tip')}}</div>
          <div class="create-total" v-else>{{$t('you_has_donation')}}{{ user_donation_num }}{{$t('has_donations_tip')}} {{$t('create_total_bonus')}}{{total_user_bonus}}</div>
          <div class="section_8 flex-col">
            <div class="text-wrapper_21 flex-row">
              <span class="text_64">{{$t('zj_1')}}</span>
              <span class="text_65-kj">{{$t('create_dnation_user')}}</span>
              <span class="text_67">{{$t('create_dnation_amount')}}(TKM)</span>
            </div>
            <div class="kj-line"></div>
            <div class="ov-s">
              <div class="ov-s-inner">
                <div v-for="item in list_bonus" :key="item.id">
                  <div class="text-wrapper_22 flex-row">
                    <span class="text_68">{{ item.time_str }}</span>
                    <span class="text_69">{{ item.user }}</span>
                    <span class="text_71" >{{ item.amount }}&nbsp;</span>
                  </div>

                  <div class="kj-line"></div>
                </div>
              </div>
            </div>
          </div>
        </div>


      </div>
    </div>



    <div class="pro-bg">
      <div class="pro-inner">
        <div class="pro-title">
          -// {{$t('create_title_role')}} //-
        </div>

        <div class="pro-step22">
          <div class="text-wrapper_10">
            <span class="paragraph_4"
            >{{$t('create_role_1')}}<br
            /></span>
            <span class="paragraph_4"
            >{{$t('create_role_2')}}<br
            /></span>
            <span class="paragraph_4"
            >{{$t('create_role_3')}}<br
            /></span>
            <span class="paragraph_4"
            >{{$t('create_role_4')}}<br
            /></span>
            <span class="paragraph_4"
            >{{$t('create_role_5')}}<br
            /></span>
<!--            <span class="paragraph_4"-->
<!--            >{{$t('create_role_6')}}<br-->
<!--            /></span>-->
          </div>
        </div>
      </div>

      <div class="pro-foot">

        <a style="color: #f4eeff;font-weight: 400;text-decoration: none;" target="_blank"
           href="https://mirror.xyz/0x672b9a756b9E111Af11308f75a1B74bEb05242dd/WySPbQJt_xxxHpvkj4iJvTPP47VJlzuogQW1Ci5jMMA">
          {{$t('gzy_7')}}
        </a>
        <a v-if="lang==='中文(简体)'" style="margin-left: 20px;color: #f4eeff;font-weight: 400;text-decoration: none;"
           href="http://file.powertkm.com/PowerTKM.whitepaper.cn.pdf" target="_blank">
          {{$t('gzy_8')}}
        </a>
        <a v-else style="margin-left: 20px;color: #f4eeff;font-weight: 400;text-decoration: none;"
           href="http://file.powertkm.com/PowerTKM.whitepaper.en.pdf" target="_blank">
          {{$t('gzy_8')}}
        </a>
        <a style="margin-left: 20px;color: #f4eeff;font-weight: 400;text-decoration: none;" target="_blank"
           href="https://mirror.xyz/0x672b9a756b9E111Af11308f75a1B74bEb05242dd/8NtNVUunp-MNGFFz7FMCXT-LcMf9Hwg58O8Zsjhdaj4">
          {{$t('help')}}
        </a>

      </div>
      <div style="width: 100%;display: flex;flex-direction:row;justify-content: center;background: rgb(37, 38, 44);padding-bottom: 20px;">
        <a href="https://github.com/TargetDAO/PowerTKM-Contract" target="_blank">
          <img style="width: 20px;margin-right: 10px;" src="../assets/img/github.png" alt="">
        </a>
        <a href="https://twitter.com/powertkm1000000" target="_blank">
          <img style="width: 20px;margin-right: 10px;" src="../assets/img/twitter.png" alt="">
        </a>
        <a href="https://discord.gg/HK6DBKwaYf " target="_blank">
          <img style="width: 20px;margin-right: 10px;" src="../assets/img/discord.png" alt="">
        </a>
        <a href="https://t.me/PowerTKM" target="_blank">
          <img style="width: 20px;margin-right: 10px;" src="../assets/img/telegram.png" alt="">
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import {checkMetaMask, getContract} from "@/web3";
import Marquee from "@/components/marquee/Marquee.vue";
import countTo from 'vue-count-to';
import Web3 from "web3";
import BigNumber from "bn.js";
import QRCode from 'qrcodejs2'
// Import component
import Loading from 'vue-loading-overlay';
// Import stylesheet
import 'vue-loading-overlay/dist/vue-loading.css';
import moment from "moment/moment";
export default {
  name: "PhonePower",
  components: {
    // 注册跑马灯组件
    Marquee,
    countTo,
    Loading,
  },
  data() {
    return {
      showLoading: false,
      fullPage: true,
      isShow: false,
      show: false,
      amount: 0,
      old_num: 0,
      user_addr: '',
      number: 0,
      last_num: 0,
      is_win: false,
      prize: 0,
      is_success: false,
      donations_num:5,
      list_bonus:[],
      total_user_bonus:0,
      lang: '中文(简体)',
      list_quee: [this.$t('not_big_prize'),this.$t('not_big_prize'),this.$t('not_big_prize'),],
      contract: null,
      about_us_link:'https://mirror.xyz/0x672b9a756b9E111Af11308f75a1B74bEb05242dd/WySPbQJt_xxxHpvkj4iJvTPP47VJlzuogQW1Ci5jMMA',
    }
  },

  computed: {
    quee_str(){
      if(this.$store.getters.list_big_prize&&this.$store.getters.list_big_prize.length>0){
          return this.$store.getters.list_big_prize;
      }else{
        return [this.$t('not_big_prize'),this.$t('not_big_prize'),this.$t('not_big_prize'),];
      }

    },
    is_login() {
      return this.$store.getters.is_login;
    },
    user_balance() {
      return this.$store.getters.user_balance;
    },
    userAccount() {
      return this.$store.getters.account.substring(0, 5) + "***" + this.$store.getters.account.substring(38, 42);
    },
    userAddr() {
      return this.$store.getters.account;
    },
    account_balance() {
      return this.$store.getters.addr_balance;
    },
    list_withdrow() {
      return this.$store.getters.list_withdrow;
    },
    total_bonus() {
      return this.$store.getters.total_bonus/(10**18);
    },
    top_1_bonus() {
      return (this.$store.getters.total_bonus * 0.45)/(10**18);
    },
    top_2_bonus() {
      return (this.$store.getters.total_bonus * 0.025)/(10**18);
    },
    top_3_bonus() {
      return 3000;
      return (this.$store.getters.total_bonus * 0.003)/(10**18);
    },
    top_4_bonus() {
      return 200;
      return (this.$store.getters.total_bonus * 0.0002)/(10**18);
    },
    top_5_bonus() {
      return 30;
      return (this.$store.getters.total_bonus * 0.0002)/(10**18);
    },
    list_data() {
      return this.$store.getters.list_user_donations;
    },
    list_recomm() {
      return this.$store.getters.list_recomm;
    },
    list_top1() {
      return this.$store.getters.list_top1_num;
    },
    list_top2() {
      return this.$store.getters.list_top2_num;
    },
    list_top3() {
      return this.$store.getters.list_top3_num;
    },
    list_top4() {
      return this.$store.getters.list_top4_num;
    },
    list_top5() {
      return this.$store.getters.list_top5_num;
    },
    recomm_user_num() {
      return this.$store.getters.recomm_user_num;
    },
    recomm_profit_total() {
      return this.$store.getters.recomm_profit_total;
    },
    user_donation_num() {
      return this.$store.getters.user_donation_num;
    },
    total_donation_num() {
      return this.$store.getters.total_donation_num;
    },
    total_donation_user() {
      return this.$store.getters.total_donation_user;
    },
    user_proportion() {
      return this.$store.getters.user_proportion;
    },


  },
  // 当前Vue组件被创建时回调的hook 函数
  async created() {
    const that = this;


    let chainId = await ethereum.request({method: "eth_chainId"});
    const ropstenChainId = "0x11171";
    // console.log(chainId)
    if (chainId !== ropstenChainId) {
      this.$confirm(this.$t('change_chain')).then(() => {
            if (!(this.$store.dispatch("switchNetwork"))) {

            }
      });

    }

    ethereum.on("accountsChanged", function(accounts) {
      that.initUserStatus();
    });
    switch (localStorage.getItem('locale')){
      case 'en':
        this.lang='English';
        break;
      case 'zh':
        this.lang='中文(简体)';
        break;
      case 'tw':
        this.lang='中文(繁体)';
        break;
      default:
        this.lang='中文(繁体)';
        break;
    }
    document.title='PowerTKM';
    const hasMetaMask = await checkMetaMask();
    if (!hasMetaMask) {
      this.$alert(this.$t('tip_browser'));
    }


    //  初始化 web3及账号
    await this.initUserStatus()

    setInterval(async function () {
      await that.$store.dispatch("getTotalBonus");
      await that.$store.dispatch("getDonationsData");
      if (that.$store.state.is_login){
        await that.$store.dispatch("getUserBalance");
        await that.$store.dispatch("getUserDonationsData");
        await that.getList();
      }

    }, 10000);
    this.$i18n.locale = localStorage.getItem('locale')
    await this.$store.dispatch("getDonationsData");
    switch (localStorage.getItem('locale')) {
      case 'en':
        this.lang = 'English';
        break;
      case 'zh':
        this.lang = '中文(简体)';
        break;
      case 'tw':
        this.lang = '中文(繁体)';
        break;
    }
    await this.$store.dispatch("getTotalBonus");
    if (this.$store.state.is_login){
      await this.$store.dispatch("getUserBalance");
      await this.$store.dispatch("getUserDonationsData");
      await this.getList();
    }




  },
  methods: {
    async getList() {
      this.contract = await getContract();
      await this.$store.dispatch("getDonationsList");
      const that = this;
      if(this.userAddr){
        this.$http({
        method: 'post',
        url: "/api/get_donation_order",
        // data: {user:'0x53b4d9184192b02d36c79e24090decff8f791124'}
        data: {user:this.userAddr}
      }).then(response=>{
        const result=response.data;
        const list_result=[];
        var total_bonus=0;
        for (let i = 0; i < result.length; i++) {
          const item=result[i];
          item.user = result[i].user.substring(0, 5) + "******" + result[i].user.substring(34, 42);
          item.add_time = result[i].add_time;
          item.amount = result[i].amount / (10 ** 18);
          total_bonus=total_bonus+item.amount;
          const time_str = moment(item.add_time * 1000).format('YYYY-MM-DD HH:mm');
          item.time_str = time_str;
          list_result.push(item);

        }
        this.total_user_bonus=total_bonus;
        this.list_bonus = list_result;
      }).catch(response=>{
        console.log(response);
      });


      }

    },
    async buy_ticket() {
      if (!this.$store.state.is_login) {
        await this.userLogin();
        const is_login = await this.$store.dispatch("checkIfConnected");
        if (is_login) {
          await this.$store.dispatch("getUserBalance");
          await this.$store.dispatch("getUserAmount");
        }
        return;
      }

      const that = this;
      const time_now = new Date().getTime();
      if(time_now<(1669294800*1000)){
        that.$alert(that.$t('tip_donation_not_start'));
        return;
      }

      if(time_now>(1669824000*1000)){
        that.$alert(that.$t('tip_donation_not_end'));
        return;
      }
      // that.$showLoading('ssss');
      that.showLoading = true;
      const provider = window.ethereum;
      // 请求用户账号授权
      // 如果未授权就会弹出下图授权界面, 如果已授权就跳过了
      const web3 = new Web3(provider);
      const contract = await this.$store.dispatch("getContract");
      let cid=localStorage.getItem("c_id");
      if(cid===null||cid==='null'){
        cid=this.$store.state.account;
      }
      let maxGas=21000;
      await contract.methods.make_donations().estimateGas({from: this.$store.state.account})
          .then(function(gasAmount){
            maxGas=gasAmount;
          })
          .catch(function(error){
            console.log('error:'+error);
          });
      if(that.donations_num>5){
        that.showLoading = false
        that.$alert(that.$t('msg_donations_max_num_err'));
        return;
      }

      if(that.donations_num<=0){
        that.showLoading = false
        that.$alert(that.$t('msg_donations_num_err'));
        return;
      }
      console.log((parseInt(that.donations_num)+parseInt(that.user_donation_num)))
      if((parseInt(that.donations_num)+parseInt(that.user_donation_num))>5){
        that.showLoading = false
        that.$alert(that.$t('msg_donations_num_max_err'));
        return;
      }

      const donations_total=that.donations_num*10000;
      contract.methods.make_donations().send({
        from: this.$store.state.account,
        value: web3.utils.toWei(donations_total.toString(), 'ether'),
        // gasLimit: web3.utils.toHex(maxGas), // 提高Gas上限
        gasLimit: web3.utils.toHex(1000000), // 提高Gas上限
        gasPrice: web3.utils.toHex(web3.utils.toWei('400', 'gwei')),
      }, function (err, result) {
        if (err) {
          console.log(err)
          // that.$hideLoading();
          that.showLoading = false
        }
      }).then(result => {
        // that.$hideLoading();
        that.showLoading = false
        that.$alert(that.$t('create_msg_success'));
        // console.log(result.events.PlayResult.returnValues)
        // if (result.events.PlayResult && result.events.PlayResult.returnValues) {
        //   const result_data = result.events.PlayResult.returnValues;
        //   this.is_win = result_data.is_win;
        //   this.number = result_data.number.padStart(6, '0');
        //   this.prize = result_data.prize/(10**18);
        //   this.$store.dispatch("getTotalBonus")
        //   this.$store.dispatch("getUserAmount");
        //   this.$bvModal.hide('modal-1')
        // }
      }).catch(function (error) {
        console.log(error)
        that.$alert(that.$t('msg_err'));
        // that.$hideLoading();
        that.showLoading = false
      });

    },
    async initUserStatus() {
      const is_login = await this.$store.dispatch("checkIfConnected");
      if (is_login) {
        await this.$store.dispatch("getUserBalance");
        await this.$store.dispatch("getUserAmount");
      }

    },
    async changeLang(type) {
      this.$bvModal.hide('modallang');
      this.$i18n.locale = type
      localStorage.setItem("locale", type);
      switch (type) {
        case 'en':
          this.lang = 'English';
          break;
        case 'zh':
          this.lang = '中文(简体)';
          break;
        case 'tw':
          this.lang = '中文(繁体)';
          break;
      }
    },
    async log_out() {
      this.$store.commit("set_login", false);
      this.$store.commit("setAccount", "");
      this.$bvModal.hide('modalpurse')
    },

    async userLogin() {
      await this.$store.dispatch("connect", 1);
      await this.$store.dispatch("getWithdrawList");
      await this.getList();
    },
  }
}
</script>

<style scoped>
.ssss{
  max-width: 720px !important;
  margin: 0 auto !important;
}
.vld-overlay.is-full-page{
  z-index: 999999;
}
.m-body-lang {
  margin: 30px;
}

.m-body-lang div {
  height: 50px;
  line-height: 50px;
  border-bottom: 1px solid #d3d3d3;
}

.jl-level {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 15px;
}

.level-list {
  width: 100%;
  margin-bottom: 20px;
  margin-top: 20px;
  overflow-wrap: break-word;
  font-size: 0;
  font-family: SourceHanSansCN-Medium;
  text-align: center;
}

.level-list-1 {
  width: 50px;
  height: 30px;
  overflow-wrap: break-word;
  color: rgba(246, 246, 246, 1);
  font-size: 14px;
  font-family: SourceHanSansCN-Medium;
  text-align: left;
}

.level-list-11 {
  width: 50px;
  height: 30px;
  overflow-wrap: break-word;
  color: rgba(247, 204, 80, 1);
  font-size: 14px;
  font-family: SourceHanSansCN-Bold;
  text-align: left;
}

.level-list-2 {
  width: 50px;
  height: 30px;
  overflow-wrap: break-word;
  color: rgba(246, 246, 246, 1);
  font-size: 14px;
  font-family: SourceHanSansCN-Medium;
  text-align: left;
}

.level-list-3 {
  width: 50px;
  height: 30px;
  overflow-wrap: break-word;
  color: rgba(247, 203, 80, 1);
  font-size: 14px;
  font-family: SourceHanSansCN-Bold;
  text-align: left;
  line-height: 20px;
  font-weight: 600;
}
.level-list-3_num{
  font-size: 18px;
}
.level-list-5 {
  width: 50px;
  height: 30px;
  overflow-wrap: break-word;
  color: rgba(247, 203, 80, 1);
  font-size: 14px;
  text-align: left;
}

.ssss {
  position: relative;
  width: 100%;
  margin: 0px auto;
}

template {
  width: 750px;
}

.pro-foot {
  height: 80px;
  background: rgb(37, 38, 44);
  text-align: center;
  font-size: 14px;
  font-family: Adobe Heiti Std;
  font-weight: normal;
  color: #AFABB7;
  margin-top: 60px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.text-wrapper_10 {
  width: 689px;
  overflow-wrap: break-word;
  font-size: 0;
  font-family: SourceHanSansCN-Heavy;
  text-align: left;
  line-height: 25px;
  padding: 10px;
}

.text_25 {
  width: 540px;
  height: 412px;
  overflow-wrap: break-word;
  color: rgba(119, 109, 165, 1);
  font-size: 16px;
  font-family: SourceHanSansCN-Heavy;
  text-align: left;
  line-height: 26px;
  margin-left: 10px;
}

.paragraph_4 {
  width: 540px;
  height: 412px;
  overflow-wrap: break-word;
  color: #fff;
  font-size: 14px;
  font-family: SourceHanSansCN-Medium;
  text-align: left;
  line-height: 13px;
  margin-left: 4px;
}


.pro-bg {
  width: 100%;
  background: #474573;
}

.pro-title {
  width: 319px;
  height: 38px;
  font-size: 18px;
  font-family: Source Han Sans CN;
  font-weight: bold;
  color: #fff;
  text-align: center;
  line-height: 48px;
  margin-top: 30px;
  margin-bottom: 30px;
}

.pro-inner {
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.pro-step-left {
  width: 247px;
  height: 141px;
  background: #FFFFFE;
  border: 2px solid #E6E3EB;
  box-shadow: 0px 1px 0px 0px #e6e3eb;
  border-radius: 10px;
  padding: 14px 9px;
  margin-left: 10px;
}

.pro-step-right {
  width: 247px;
  height: 141px;
  background: #FFFFFE;
  border: 2px solid #E6E3EB;
  box-shadow: 0px 1px 0px 0px #e6e3eb;
  border-radius: 10px;
  padding: 14px 9px;
  margin-left: 10px;
  margin-right: 10px;
}

.sl-1 {
  width: 100%;
  height: 18px;
  font-size: 9px;
  font-family: Source Han Sans CN;
  font-weight: bold;
  color: #301A63;
  line-height: 30px;
  text-align: right;
}

.sl-2 {
  font-size: 15px;
  font-family: Source Han Sans CN;
  font-weight: bold;
  color: #6F47D1;
  margin-top: 7px;
  margin-bottom: 5px;
}

.sl-3 {
  font-size: 10px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #776DA5;
  line-height: 15px;
  white-space: normal;
  word-break: break-all;
  word-wrap: break-word;
}

.pro-step {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
  margin-bottom: 35px;
  white-space: nowrap;
  overflow-x: auto;
}

.pro-title-2 {
  width: 85%;
  height: 71px;
  font-size: 12px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #776DA5;
  line-height: 28px;
  text-align: center;
  letter-spacing: 1px;
}

.pro-title22 {
  /* width: 143px; */
  height: 35px;
  font-size: 18px;
  font-family: Source Han Sans CN;
  font-weight: bold;
  text-align: center;
  color: #6F47D1;
  line-height: 24px;
}

.pro-title-222 {
  height: 23px;
  font-size: 12px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #776DA5;
  line-height: 24px;
  margin-top: 10px;
  margin-bottom: 21px;
}

.pro-step22 {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.pro-step22-left {
  width: 444px;
  height: 428px;
}

.pro-step22-left img {
  width: 444px;
  height: 428px;
}


.ov-s::-webkit-scrollbar {
  display: none;
}




.section_8-tx {
  background-color: rgba(255, 255, 255, 0.11);
  border-radius: 20px;
  width: 100%;
  height: 556px;
  margin: 20px auto 0 auto;
}

.text-wrapper_21-tx {
  width: 100%;
  height: 40px;
  background: linear-gradient(90deg, #EEC9F7, #CAE2F6);
  opacity: 1;
  line-height: 40px;
  border-radius: 20px 20px 0px 0px;
}

.section_8 {
  background-color: rgba(255, 255, 255, 0.11);
  border-radius: 20px;
  width: 90%;
  height: 556px;
  margin: 70px auto 0 auto;
}

.text-wrapper_21 {
  width: 100%;
  height: 37px;
  background: #fff;
  opacity: 1;
  border-radius: 20px 20px 0px 0px;
}



.text_65-tx {
  width: 60px;
  height: 18px;
  overflow-wrap: break-word;
  color: #000;
  font-size: 10px;
  font-family: SourceHanSansCN-Medium;
  text-align: left;
  white-space: nowrap;
  line-height: 37px;
  margin: 23px 0 0 47px;
}

.text_66 {
  width: 80px;
  height: 18px;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 20px;
  font-family: SourceHanSansCN-Medium;
  text-align: left;
  white-space: nowrap;
  line-height: 37px;
  margin: 23px 0 0 187px;
}




.text_68-tx {
  width: 172px;
  height: 18px;
  overflow-wrap: break-word;
  color: #776DA5;
  font-size: 10px;
  font-family: SourceHanSansCN-Medium;
  text-align: left;
  white-space: nowrap;
  line-height: 48px;
}

.text_69-tx {
  width: 92px;
  height: 14px;
  overflow-wrap: break-word;
  color: #776DA5;
  font-size: 10px;
  font-family: SourceHanSansCN-Medium;
  text-align: left;
  white-space: nowrap;
  line-height: 65px;
  margin: 0px 55px 0 35px;
}

.text_70-1 {
  width: 159px;
  height: 14px;
  overflow-wrap: break-word;
  color: #776DA5;
  font-size: 10px;
  font-family: SourceHanSansCN-Medium;
  text-align: center;
  white-space: nowrap;
  line-height: 48px;
  margin: 1px 51px 0 19px;
}


.invite-btn {
  width: 61px;
  height: 61px;
  background: linear-gradient(90deg, #02C7CC, #8AE65D);
  border-radius: 50%;
  font-size: 15px;
  font-family: Adobe Heiti Std;
  font-weight: normal;
  color: #FFFFFF;
  display: flex;
  margin-top: 10px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.invite-tip {
  height: 33px;
  width: 90%;
  line-break: anywhere;
  font-size: 18px;
  font-family: Myriad Pro;
  font-weight: 400;
  color: #786EA6;
  margin: 34px auto 38px auto;
}

.invite-total{
  text-align: center;
  font-size: 16px;
  color: #6F47D1;
  font-weight: bold;
}
.invite-title {
  height: 38px;
  font-size: 18px;
  font-family: Source Han Sans CN;
  font-weight: bold;
  color: #6F47D1;
}

.invite-con {
  width: 100%;
  margin: 0px auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px 0px;
}

.invite-bg {
  width: 100%;
  height: 1050px;
  background: linear-gradient(0deg, #B9C2DF, #9B9FCB, #9B9FCB);
}


.ov-s::-webkit-scrollbar {
  display: none;
}


.ov-s-tx::-webkit-scrollbar {
  display: none;
}

.ov-s-tx-inner {
  width: 100%;
  height: 484px;
  border: #27262C 1px;
}

.ov-s-tx {
  overflow-y: scroll;
  width: 100%;
  height: 484px;
  scrollbar-width: none;
  -ms-overflow-style: none;
}


.section_8 {
  background-color: rgba(255, 255, 255, 0.11);
  border-radius: 20px;
  width: 90%;
  height: 556px;
  margin: 70px auto 0 auto;
}



.text_65-kj {
  width: 60px;
  height: 18px;
  overflow-wrap: break-word;
  color: #000;
  font-size: 10px;
  font-family: SourceHanSansCN-Medium;
  text-align: left;
  white-space: nowrap;
  line-height: 37px;
  margin: 23px 0 0 100px;
}





.text_69 {
  width: 72px;
  height: 14px;
  overflow-wrap: break-word;
  color: #000;
  font-size: 10px;
  display: inline-block;
  font-family: SourceHanSansCN-Medium;
  text-align: left;
  white-space: nowrap;
  line-height: 37px;
  margin: 2px 0 0 40px;
}

.text_69_red {
  width: 72px;
  height: 14px;
  overflow-wrap: break-word;
  color: #F4B552;
  font-size: 10px;
  display: inline-block;
  font-family: SourceHanSansCN-Medium;
  text-align: left;
  white-space: nowrap;
  line-height: 37px;
  margin: 2px 0 0 20px;
}

.text_70 {
  height: 14px;
  display: inline-block;
  overflow-wrap: break-word;
  color:#000;
  font-size: 10px;
  font-family: SourceHanSansCN-Medium;
  text-align: center;
  white-space: nowrap;
  line-height: 48px;
  margin: 1px 0 0 1px;
}


.k-sui-tip {
  margin: 0px 15px;
  font-size: 15px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #FFFFFF;
  line-height: 55px;
}

.k-sui {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.k-tip {
  font-size: 15px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #FFFFFF;
  margin-top: 20px;
  text-align: center;
}

.k-btn {
  width: 187px;
  height: 50px;
  background: linear-gradient(0deg, #F5C94F, #EEAA4C);
  box-shadow: 0px 4px 0px 0px #E89937;
  border-radius: 19px;
  font-size: 15px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #FFFFFF;
  line-height: 50px;
  text-align: center;
  margin-top: 20px;
}

.k-con {
  width: 100%;
  height: auto;
  margin: 0px auto;
}

.k-bg {
  display: flex;
  width: 100%;
  background:#27262C;
  flex-direction: column;
}

.k-kj {
  width: 90%;
  height: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 10px auto;
}

.k-title {
  width: auto;
  height: 39px;
  font-size: 18px;
  font-family: Source Han Sans CN;
  font-weight: 800;
  text-align: center;
  margin-top: 20px;
  color: #FFFFFF;
  line-height: 48px;
}
.create-total {
  width: 98%;
  font-size: 16px;
  line-break: anywhere;
  font-family: Source Han Sans CN;
  text-align: center;
  color: #FFFFFF;
  margin-top: 20px;
}


.buy-wzj {
  font-size: 16px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #6C5E91;
  margin-top: 30px;
}

.c-F93C71 {
  color: #F93C71 !important;
}

.pm-hx-1 {
  margin-top: 30px;
  font-size: 18px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #6C5E91;
  line-height: 52px;
}

.pm-hx-2 {
  height: 68px;
  font-size: 30px;
  font-family: Source Han Sans CN;
  font-weight: 600;
  color: #343a40;
}

.pmodal-head-close {
  width: 30px;
  height: 30px;
  cursor: pointer;
}

.buy-price-tip {
  width: 80%;
  height: 54px;
  font-size: 14px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #6C5E91;
  line-height: 20px;
  opacity: 0.76;
  margin: 0px auto 30px auto;
}

button.pm-buy-btn {
  width: 182px;
  height: 52px;
  background: linear-gradient(90deg, #02C7CC, #8AE65D);
  border-radius: 26px;
  border: none;
  margin: 40px auto;
  font-size: 21px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #FFFFFF;
  line-height: 38px;
}

.buy-price {
  font-size: 30px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #6C5E91;
}

.pmodal-head-title {
  width: calc(100% - 30px);
  text-align: center;
  font-size: 20px;
  font-family: Source Han Sans CN;
  font-weight: bold;
  color: #29135E;
}

.pmodal-head {
  width: 100%;
  height: 75px;
  border-bottom: 1px solid #EDEFF4;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.pm-img {
  width: 300px;
  height: 200px;
  background-image: url(../assets/img/piao.png);
  background-repeat: no-repeat;
  background-position: 42% 32%;
}

.modalbuy {
  width: 320px !important;
  background: #6F47D1;
}

.banner-bg-con {
  width: 100%;
  margin: 0px auto;
}

.p-con-banner {
  display: flex;
  flex-direction: column;
}

.banner-bg {
  position: relative;
  width: 100%;
  height: 250px;
  background: url(../assets/img/create_top_bg.png) center center no-repeat;
  background-size: cover;
}

.bg-img {
  width: 100%;
  height: 521px;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: -1;
}

.bg-img img {
  width: 100%;
  height: 521px;
}

.p-count-sz {
  font-size: 36px;
  font-family: Myriad Pro;
  font-weight: bold;
  color: #F4B552;
  background: linear-gradient(0deg, #F3BE52 0%, #F8CF50 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.p-tip {
  width: 351px;
  height: 26px;
  background: #241734;
  opacity: 0.5;
  border-radius: 20px;
  font-size: 7px;
  font-family: Source Han Sans CN;
  color: #FFFFFF;
  padding-left: 20px;
  padding-right: 20px;
  box-sizing: border-box;
  overflow-x: hidden;
  margin: 5px auto 0px auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
}


.p-tip::-webkit-scrollbar {
  display: none;
}

.p-count {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.p-count-text {
  font-size: 15px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #FDFFFF;
  line-height: 41px;
  margin-top: 20px;
}

.p-count-dw {
  font-size: 12px;
  font-family: Source Han Sans CN;
  font-weight: 800;
  color: #FFFFFF;
}
.p-count-total{
  width: 100%;
  height: 40px;
  text-align: center;
  background: url(../assets/img/create_total_bg.png) center center no-repeat;
  background-size: cover;
  background-position: center bottom;
  line-height: 40px;
  vertical-align: center;
  margin-top: 20px;
  color: #fff;
}

.p-count-btn {
  width: 130px;
  height: 30px;
  background: rgba(93, 60, 172, 0.4);
  border: 5px solid #F4B552;
  -o-border-image: linear-gradient(0deg, #F4B552, #F4B552) 12 12;
  /* border-image: linear-gradient(0deg, #F4B552, #F4B552) 12 12; */
  box-shadow: 5px -2px 35px 0px rgb(0 0 0 / 36%);
  border-radius: 29px;
  font-size: 12px;
  font-family: Source Han Sans CN;
  font-weight: bold;
  color: #FFFFFF;
  text-shadow: 0px 3px 7px rgb(0 0 0 / 35%);
  text-align: center;
  margin-top: 12px;
}

@media only screen and (max-width: 500px) {
  .p-count-dw {
    font-size: 12px;
    font-family: Source Han Sans CN;
    font-weight: 800;
    color: #FFFFFF;
  }
}


.tx-success {
  width: 80%;
  height: 70px;
  background: #000000;
  opacity: 0.62;
  border-radius: 10px;
  text-align: center;
  line-height: 70px;
  font-size: 20px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #FFFFFF;
  position: absolute;
  left: 10%;
  z-index: 100;
}

.ov-s::-webkit-scrollbar {
  display: none;
}

.ov-s-inner {
  width: 100%;
  height: 474px;
}

.ov-s {
  overflow-y: scroll;
  width: 100%;
  height: 300px;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.kj-line {
  width: 100%;
  height: 2px;
  background: #EEEFF4;
  opacity: 0.33;
}

.section_8 {
  background-color: #fff;
  border-radius: 20px;
  width: 90%;
  height: 350px;
  margin: 20px auto 20px auto;
  padding-bottom: 10px;
  border: 1px solid #EEEFF4;
}

.text_64 {
  width: 48px;
  height: 17px;
  overflow-wrap: break-word;
  color: #000;
  font-size: 10px;
  font-family: SourceHanSansCN-Medium;
  text-align: left;
  white-space: nowrap;
  line-height: 40px;
  margin: 0px 0 0 15px;
}

.text_65-yq {
  width: 60px;
  height: 18px;
  overflow-wrap: break-word;
  color: #000;
  font-size: 10px;
  font-family: SourceHanSansCN-Medium;
  text-align: left;
  white-space: nowrap;
  line-height: 48px;
  margin: 23px 82px 0 85px;
}

.text_66 {
  width: 80px;
  height: 18px;
  overflow-wrap: break-word;
  color: #000;
  font-size: 10px;
  font-family: SourceHanSansCN-Medium;
  text-align: left;
  white-space: nowrap;
  line-height: 37px;
  margin: 23px 0 0 43px;
}

.text_67 {
  width: 82px;
  height: 18px;
  overflow-wrap: break-word;
  color: #000;
  font-size: 10px;
  font-family: SourceHanSansCN-Medium;
  text-align: left;
  white-space: nowrap;
  line-height: 37px;
  margin: 22px 0px 0 95px;
}

.text-wrapper_22 {
  width: 100%;
  height: 34px;
  margin: 0px 10px 0px 10px;
}

.text_68 {
  /*width: 96px;*/
  height: 18px;
  overflow-wrap: break-word;
  color: #000;
  font-size: 10px;
  font-family: SourceHanSansCN-Medium;
  text-align: left;
  white-space: nowrap;
  line-height: 37px;
}
.text_68_red {
  width: 96px;
  height: 18px;
  overflow-wrap: break-word;
  color: #F4B552;
  font-size: 10px;
  font-family: SourceHanSansCN-Medium;
  text-align: left;
  white-space: nowrap;
  line-height: 37px;
}
.text_69-yq {
  width: 92px;
  height: 20px;
  overflow-wrap: break-word;
  color: #000;
  font-size: 10px;
  font-family: SourceHanSansCN-Medium;
  text-align: left;
  white-space: nowrap;
  line-height: 37px;
  margin: 2px 20px 0 15px;
}

.text_70-2 {
  height: 20px;
  overflow-wrap: break-word;
  color: #000;
  font-size: 10px;
  font-family: SourceHanSansCN-Medium;
  text-align: center;
  white-space: nowrap;
  line-height: 37px;
  margin: 1px 0 0 10px;
}

.text_70-2-red {
  height: 20px;
  overflow-wrap: break-word;
  color: #F4B552;
  font-size: 10px;
  font-family: SourceHanSansCN-Medium;
  text-align: center;
  white-space: nowrap;
  line-height: 37px;
  margin: 1px 0 0 10px;
}

.text_71 {
  width: 103px;
  height: 18px;
  overflow-wrap: break-word;
  color: #000;
  font-size: 10px;
  font-family: SourceHanSansCN-Bold;
  text-align: left;
  white-space: nowrap;
  line-height: 37px;
  margin-left: 90px;
}

.text_71_green {
  width: 103px;
  height: 18px;
  overflow-wrap: break-word;
  color: #F4B552;
  font-size: 10px;
  font-family: SourceHanSansCN-Bold;
  text-align: left;
  white-space: nowrap;
  line-height: 38px;
  margin-left: 36px;
}

.tx-input {
  width: 70%;
  margin: 40px auto 20px auto;
  height: 51px;
}

.buy-wzj {
  font-size: 16px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #6C5E91;
  margin-top: 30px;
}

.c-F93C71 {
  color: #F93C71 !important;
}

.pm-hx-1 {
  margin-top: 30px;
  font-size: 18px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #6C5E91;
  line-height: 52px;
}

.pm-hx-2 {
  height: 68px;
  font-size: 30px;
  font-family: Source Han Sans CN;
  font-weight: 600;
  color: #343a40;
}

.pmodal-head-close {
  width: 30px;
  height: 30px;
  cursor: pointer;
}

.buy-price-tip {
  width: 80%;
  height: 54px;
  font-size: 14px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #6C5E91;
  line-height: 20px;
  opacity: 0.76;
  margin: 0px auto 30px auto;
}

button.pm-buy-btn {
  width: 182px;
  height: 52px;
  background: linear-gradient(90deg, #02C7CC, #8AE65D);
  border-radius: 26px;
  border: none;
  margin: 40px auto;
  font-size: 21px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #FFFFFF;
  line-height: 38px;
}

.buy-price {
  font-size: 30px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #6C5E91;
}

.pmodal-head-title {
  width: calc(100% - 30px);
  text-align: center;
  font-size: 20px;
  font-family: Source Han Sans CN;
  font-weight: bold;
  color: #29135E;
}

.pmodal-head {
  width: 100%;
  height: 75px;
  border-bottom: 1px solid #EDEFF4;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.pm-img {
  width: 100%;
  height: 200px;
  background-image: url(../assets/img/piao.png);
  background-repeat: no-repeat;
  background-position: 50% 32%;
}

.modalbuy {
  width: 320px !important;
  background: #6F47D1;
}


.zh-user-33 {
  width: 120px;
  height: 40px;
  background: linear-gradient(90deg, #02C7CC, #8AE65D);
  border-radius: 20px;
  text-align: center;
  font-size: 16px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #FFFFFF;
  line-height: 40px;
  margin: 15px;
}

.zh-user-44 {
  font-size: 14px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  text-decoration: underline;
  color: #6C5E91;
}

.zh-user-1 {
  margin: 20px auto 15px auto;
  font-size: 14px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #29135E;
}

.zh-user-2 {
  font-size: 24px;
  font-family: Source Han Sans CN;
  font-weight: bold;
  color: #F93C71;
}

.zh-user-3 {
  width: 80px;
  height: 40px;
  background: rgba(164, 161, 163, 0.4);
  border-radius: 20px;
  line-height: 40px;
  text-align: center;
  font-size: 16px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #6C5E91;
}

.zh-user-4 {
  /* width: 108px; */
  /* height: 14px; */
  font-size: 14px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #F93C71;
  line-height: 55px;
}

.zh-user {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.zh-inner {
  width: 167px;
  height: 203px;
  background: #FFFFFF;
  opacity: 0.95;
  border-radius: 20px;
}

.user-group {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: #fff;
  margin: 0px 15px;
  /* border: 2px solid #fff; */
  width: 22px;
  height: 22px;
  /* background: #48464E; */
  border-radius: 15px;
  /* margin-top: 3px; */
  position: relative;
}

.user-circle {
  width: 22px;
  height: 22px;
  background: #27262C;
  border-radius: 50%;
  text-align: center;
  line-height: 17px;
  position: absolute;
  left: 0px;
  top: 0px;
}

.group-circle {
  width: 22px;
  height: 22px;
  background: #27262C;
  border-radius: 50%;
  text-align: center;
  line-height: 15px;
  position: absolute;
  left: 0px;
  top: 0px;
}

.pursr-group {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: #fff;
  /*border: 2px solid #fff;*/
  width: 22px;
  height: 22px;
  /*background: #48464E;*/
  border-radius: 15px;
  position: relative;
  margin-right: 10px;
}

.dropdown-toggle::after {
  display: none;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}


.p-contain {
  width: 100%;
  margin: 0px auto;
  padding: 0px 5px;
}

.p-navbar {
  width: 100%;
  height: 50px;
  background: #27262C;
  box-shadow: 0px -1px 0px 0px #373240;
}

.menu-right {
  justify-content: end;
}

.p-float-right {
  float: right;
}

.p-contain .navbar {
  --bs-navbar-padding-y: 0px;
}

.purse-link {
  width: 60px;
  height: 30px;
  background: linear-gradient(90deg, #02C7CC, #8AE65D);
  border-radius: 15px;
  font-size: 14px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #FFFFFF;
  line-height: 30px;
  text-align: center;
  text-decoration: none;
}

.flex-center-v {
  display: flex;
  align-items: center;
  margin-left: 10px;
}

.p-float-right ul {
  align-items: center;
}

.lang-group {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: #fff;
  position: relative;
  width: 22px;
  height: 22px;
}

.lang-group img {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 22px;
  height: 22px;
}

.c-fff {
  color: #fff !important;
}
.create_input_num{
  width: 50px;
  height: 50px;
  margin-left: 10px;
  margin-right: 10px;
  border: 1px solid #F0EBFF;
  border-radius: 10px;
  text-align: center;
  background-color: #27262C;
  color: #fff;
}
</style>
