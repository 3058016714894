export default {
    login: "Connect",
    m: {
        login: "Connect"
    },
    logout:'Log Out',
    user_amount:'Wallet(TKM)',
    purse_amount:'Account(TKM)',
    ti_xian:'Withdrawal',
    ti_xian_jl:'Withdrawal history',
    zd:'Minimum withdrawal amount is 100TKM',
    bu_di:'Please enter withdrawal amount, must more than 100TKM',
    ri_qi:'Date',
    tx_zj:'Amount(TKM)',
    jy_hx:'Status',
    buy_now:'Buy Now',
    prize_history:'History',
    not_big_prize:'Try your luck. Win our Jackpot',
    tx_wdz:'Proccessing',
    ydz:'Finished',
    tj_je:'Prize pool',
    lk_cy:'Play Now',
    m_z:'Last ',
    jl:'win',
    ww:'digits matches:',
    dj_dz:'Jackpot winner',
    ql_jc:'Please wait',
    sh_gm:'Please wait',
    g_m:'Buy',
    d_c:'',
    d_c_yb:'Once purchase per play, reward will base on player\'s has last 6 digits',
    nd_jh:'Your number is',
    n_hd:'You win',
    n_hd_jl:'',
    hyh_wzj:'Sorry!',
    hyh_xc:'Better luck next time',
    hyh_jx:'Play again',
    lj_1:'Connect your wallet',
    lj_2:'Check result',
    lj_3:'Connect wallet',
    zj_1:'Date',
    zj_2:'No.',
    zj_3:'Result',
    zj_4:'Prize',
    zj_5:'Win',
    zj_6:'Lose',
    n_wcj:'No History',
    n_wcj_lj:'Play Now',
    y_1:'Invite a friend',
    y_2:'Invite',
    y_3:'',
    b_1:'Date',
    b_2:'Invited',
    b_3:'Reward',
    b_jl:'Reward',
    gm_1:'How to play',
    gm_2:'If the digits on your tickets match our game rule, you win a big portion of the prize pool!',
    gm_3:'Step 1',
    gm_4:'Purchase',
    gm_5:'Each purchase cost 10TKM, and each play can purchase only once',
    gm_6:'Step 2',
    gm_7:'Instant Win',
    gm_8:'A hash will be generated after purchase, last 6 digits will be used as player\'s lottery number',
    gm_9:'Reward',
    gm_10:'Base on player\'s hash last 6 digits',
    gzy_1:'Last digit is 0 or 1: reward 5 TKM ',
    gzy_2:'Same last 2 digit: reward 30 TKM',
    gzy_3:'match last 3 numbers: reward 200 TKM',
    gzy_4:'match last 4 numbers: reward 3000 TKM',
    gzy_5:'match last 5 numbers: reward 2.5% of prize pool(0.25% will be burned as tax)',
    gzy_6:'match all 6 numbers: reward 45% of prize pool(4.5% will be burned as tax)',
    gzy_7:'About',
    gzy_8:'Litepaper',
    user:'Player',
    clipboard:'Copy to clipboard!',
    tip_browser:'Please open the url in tuke wallet!',
    no_data:'No History',
    wallet:'wallet',
    language:'language',
    msg_err:'Transaction error, please try again!',
    err_not_num:'Please enter withdrawal amount!',
    err_num_les:'Cannot withdraw less than 100!',
    err_num_max:'Insufficient fund!',
    msg_success:'Success!',
    create_jc_ze:'Pool',
    create_title_role:'Fundraising rules',
    create_tip_connect:'Please connect your wallet!',
    create_title_create:'Bonus History',
    create_role_1:'1. Each fundraising pack cost 10000TKM, each wallet can purchase 5 at most.',
    create_role_2:'2. Max 100 wallet is allowed for fundraising.',
    create_role_3:'3. All wallet which purchased fundraising packs will be recorded on chain, and share PowerTKM\'s profit.',
    create_role_4:'4. Investors will be rewarded based on how many fundraising pack have they purchased.',
    create_role_5:'5. The fundraising will be ended on November 30, 2022, and PowerTKM start.',
    create_role_6:'6. When pool reaches 5000000TKM, fundraising close.',
    create_btn:'Purchase',
    msg_donations_max_num_err:'Sorry, you cannot purchase more than 5 packs!',
    msg_donations_num_err:'Please enter a correct number',
    msg_donations_num_max_err:'Sorry, you cannot purchase more than 5 packs!',
    create_dnation_amount:'Bonus',
    create_dnation_user:'Buyer',
    create_msg_success:'Purchase success!',
    msg_not_can_buy:'Game start soon!',
    tip_has:'',
    tip_your_wallet:'Wallet Address:',
    tip_has_2:' people joined，Purchase Has Stoped',
    tip_has_3:' more seats available',
    can_donation:'Purchase',
    can_donation_num:'packages ',
    has_donations_tip:' packages purchased',
    you_has_donation:' ',
    tip_user_max:'You have purchased 5 packages!',
    tip_end:'Purchase Has Stoped',
    has_invite:'',
    has_invite_num:' people invited,',
    change_chain:'Switch to thinkium？',
    help:'Help',
    tip_donation_not_start:'The donations has not started yet',
    tip_donation_not_end:'The donations has ended',
    title_reward:'Rule',
    create_total_bonus:',Total Bonus:',
}
