<template>
  <div id="app">
    <PhoneCreate></PhoneCreate>
  </div>
</template>

<script>
import PhoneCreate from './components/PhoneCreate.vue'

export default {
  name: 'App',
  components: {
    PhoneCreate
  }
}
</script>

<style>
#app {
  font-family: Source Han Sans CN;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

</style>
